import React from 'react'
import AuthFormBackgroundComponent from './components/background'

export default function AuthForm() {
  return (
    <div>
        <AuthFormBackgroundComponent />
    </div>
  )
}
